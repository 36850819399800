<template>
  <MasterDetail
    formTitle="Cadastro de Instituições"
    :cols="cols"
    :opts="opts"
    :resourceUrl="resourceUrl"
    descriptionProperty="nome"
  ></MasterDetail>
</template>

<script>
import MasterDetail from "@/components/master-detail.vue";
export default {
  components: {
    MasterDetail,
  },
  computed: {
    resourceUrl: function () {
      return `/v1/faturamento/projext/instituicao/${this.getClient().clientId}`;
    },
    resourceRegiao: function () {
      return this.apiResource("/v1/faturamento/projext/regiao");
    },
  },
  created: function () {
    this.resourceRegiao.get().then((response) => {
      this.opts.regiao = response;
    });
  },
  data: function () {
    return {
      /* 
    "empresaId": 48,
    "criterio_rateio": 0,
    "criado_em": "2021-03-27T18:12:26.000000Z",
    "modificado_em": "2021-03-27T18:12:29.000000Z"
      */
      cols: [
        {
          key: "id",
          name: "ID",
          align: 1,
          hideInform: true,
        },
        {
          key: "nome",
          name: "Nome da Instituição",
          type: this.$fieldTypes.TEXT,
          colSize: 12,
        },
        
        
        {
          key: "responsavel",
          name: "Responsável",
          type: this.$fieldTypes.TEXT,
          colSize: 12,
        },
       
        {
          key: "regiaoId",
          name: "Região",
          type: this.$fieldTypes.SELECT,
          rel: { to: "regiao", key: "id", name: "regiao" },
          rules: [{ rule: "required" }],
          colSize: 12,
        },
        
        {
          key: "criado_em",
          name: "Criado Em",
          type: this.$fieldTypes.DATETIME,
          hideInform: true,
        },
        {
          key: "modificado_em",
          name: "Modificado Em",
          type: this.$fieldTypes.DATETIME,
          hideInform: true,
        },
      ],
      opts: {
        enquadramento: [],
      },
    };
  },
};
</script>
